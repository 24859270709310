<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="100px" :inline="true" :model="filters">
        <el-form-item label="商户订单号">
          <el-input
            v-model="filters.mer_order_no"
            placeholder="商户订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="渠道订单号">
          <el-input
            v-model="filters.order_no"
            placeholder="渠道订单号"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select v-model="filters.pay_type" placeholder="请选择">
            <el-option
              v-for="item in pay_type_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="filters.pay_status" placeholder="请选择">
            <el-option
              v-for="item in pay_status_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Date">
          <el-date-picker
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择日期"
          >
          </el-date-picker>
        </el-form-item>

        <br />
        <div style="margin-left: 100px">
          <el-form-item>
            <el-button type="primary" @click="getUsers">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="reset">重置</el-button>
          </el-form-item>
          <!-- <el-form-item>
          <el-button type="primary" @click="handleAdd">新增</el-button>
        </el-form-item> -->
          <el-form-item>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      id="mainTable"
      :data="order_info"
      highlight-current-row
      v-loading="listLoading"
      style="width: 100%"
    >
      <!-- <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column type="index" width="60"> </el-table-column> -->
      <el-table-column prop="mer_order_no" label="商户订单号" width="200">
      </el-table-column>
      <el-table-column prop="order_no" label="渠道订单号">
      </el-table-column>
      <el-table-column prop="merchant_id" label="商户ID"> </el-table-column>
      <el-table-column prop="user_id" label="mch_uid"> </el-table-column>
      <el-table-column prop="notice_num" label="通知次数"> </el-table-column>
      <!-- <el-table-column prop="mch_url" label="mch_url"> </el-table-column>
      <el-table-column prop="callback_url" label="callback_url">
      </el-table-column> -->
      <el-table-column prop="pay_type" label="支付方式"> </el-table-column>
      <el-table-column prop="pay_status" label="支付状态"> </el-table-column>
      <el-table-column prop="amount" label="支付金额"> </el-table-column>
      <el-table-column prop="created_at" label="创建时间"> </el-table-column>
      <el-table-column prop="updated_at" label="更新时间"> </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next,total"
        @current-change="handleCurrentChange"
        :page-size="20"
        :total="total"
        style="float: right"
      >
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { orderListApi } from "@/utils/api";

export default {
  data() {
    return {
      filters: {
        order_no: "",
        mer_order_no:'',
        channel_orderno: "",
        pay_type: "",
        pay_status: "",
      },
      date: "",
      order_info: [],
      total: 0,
      page: 1,
      listLoading: false,
      sels: [], //列表选中列
      token: localStorage.getItem("token"),
      pay_type_list: [
        { value: "4", label: "MOMO" },
        { value: "5", label: "BANKQR" },
        { value: "ATMCARD", label: "ATMCARD " },
        { value: "1001", label: "VISA" },
      ],
      pay_status_list: [
        { value: "0", label: "待支付" },
        { value: "1", label: "已支付" },
      ],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getUsers();
    },
    //获取用户列表
    getUsers() {
      let pay_type_list = {
        4: "MOMO",
        5: "BANKQR",
        ATMCARD: "ATMCARD",
        1001: "VISA",
        ATMCARD_N:'ATMCARD'
      };
      let pay_status_list = {
        0: "待支付",
        1: "已支付",
      };
      let end_time = "";
      let start_time = "";
      if (this.date) {
        start_time = this.$dayjs(this.date[0]).unix();
        end_time = this.$dayjs(this.date[1]).unix();
      }
      let para = {
        page: this.page,
        token: this.token,
        ...this.filters,
        start_time,
        end_time,
        // name: this.filters.name,
      };
      this.listLoading = true;
      //NProgress.start();
      orderListApi(para).then((res) => {
        this.total = res.data.total;
        this.order_info = res.data.order_info;
        this.order_info.forEach((item) => {
          item.pay_type = pay_type_list[item.pay_type];
          item.pay_status = pay_status_list[item.pay_status];
        });
        this.listLoading = false;
        //NProgress.done();
      });
    },
    reset() {
      this.filters = {
        order_no: "",
        channel_orderno: "",
        pay_type: "",
        pay_status: "",
      };
      this.date = "";
      this.getUsers();
    },
    handleExport() {
      let url = "https://paym.muugamevn.com/api/exportOrder?";
      // unix()
      let start_time = "";
      let end_time = "";
      if (this.date) {
        start_time = this.$dayjs(this.date[0]).unix();
        end_time = this.$dayjs(this.date[1]).unix();
      }
      let filters = {
        ...this.filters,
        start_time,
        end_time,
        token: this.token,
      };
      for (const key in filters) {
        url = url + key + "=" + filters[key] + "&";
      }
      url = url.slice(0, -1);
      window.location.href = url;
      // this.exportExcel("列表", "mainTable");
    },
    exportExcel(filename, tableId) {
      var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
      var table = document.querySelector("#" + tableId).cloneNode(true);
      // console.log(table);
      // table.removeChild(table.querySelector(".el-table__fixed-right"));
      var wb = XLSX.utils.table_to_book(table, xlsxParam);
      /* 获取二进制字符进行输出 */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          filename + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    //根据接口返回数据下载
    // handleExport() {
    //   this.queryObj.queryData.isexport = "1";
    //   exportHospitalViewList(this.queryObj).then((response) => {
    //     var isSize = response.size;
    //     if (isSize !== undefined) {
    //       if (isSize === 2) {
    //         this.$message({
    //           message: "导出异常，请联系管理员",
    //           type: "warning",
    //         });
    //       } else {
    //         const fileName = "订单.xlsx";
    //         if (window.navigator.msSaveBlob) {
    //           //IE以及IE内核的浏览器
    //           try {
    //             window.navigator.msSaveBlob(response, fileName);
    //           } catch (e) {
    //             console.log(e);
    //           }
    //         } else {
    //           const blob = new Blob([response]);
    //           const elink = document.createElement("a"); // 创建a标签
    //           elink.download = fileName; // 为a标签添加download属性
    //           elink.style.display = "none";
    //           elink.href = URL.createObjectURL(blob);
    //           document.body.appendChild(elink);
    //           elink.click(); // 点击下载
    //           URL.revokeObjectURL(elink.href); // 释放URL 对象
    //           document.body.removeChild(elink); // 释放标签
    //         }
    //       }
    //     }
    //   });
    // },
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<style scoped></style>
